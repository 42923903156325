import cn from "classnames";
import React from "react";
import styles from "./ToggleSwitch.css";

interface ToggleSwitchProps {
  className?: string;
  isToggled: boolean;
  labelOn?: string;
  labelOff?: string;
  onToggle: () => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  className,
  isToggled,
  labelOn,
  labelOff,
  onToggle,
}) => {
  return (
    <label className={cn(styles.toggleContainer, className)}>
      <input
        type="checkbox"
        checked={isToggled}
        onChange={onToggle}
        className={styles.toggleInput}
      />
      <span className={styles.toggleSlider}></span>
      <span className={styles.toggleLabel}>
        {isToggled ? labelOn : labelOff}
      </span>
    </label>
  );
};

export default ToggleSwitch;
