/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs as  } from "relay-runtime";
export type COMMENT_SORT = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "FEATURED_AT_DESC_REACTION_DESC" | "REACTION_DESC" | "REPLIES_DESC" | "%future added value";
export type RejectedQueueRouteLocal = {
    readonly moderationQueueSort: COMMENT_SORT | null;
    readonly " $refType": "RejectedQueueRouteLocal";
};
export type RejectedQueueRouteLocal$data = RejectedQueueRouteLocal;
export type RejectedQueueRouteLocal$key = {
    readonly " $data"?: RejectedQueueRouteLocal$data;
    readonly " $fragmentRefs": FragmentRefs<"RejectedQueueRouteLocal">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RejectedQueueRouteLocal",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "moderationQueueSort",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any;
(node as any).hash = '1f32a74232c959b5fb36333ef7f9905c';
export default node;
